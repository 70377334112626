import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Cookies from 'js-cookie'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },/*
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/product/:id',
    name: 'Product',
    component: () => import('../views/Product.vue')
  },
  {
    path: '/news',
    name: 'NewsList',
    component: () => import('../views/Article/ArticlesList.vue'),
    props: {
      type: 'news'
    }
  },
  {
    path: '/events',
    name: 'EventsList',
    component: () => import('../views/Article/ArticlesList.vue'),
    props: {
      type: 'events'
    }
  },
  {
    path: '/blog',
    name: 'BlogList',
    component: () => import('../views/Article/ArticlesList.vue'),
    props: {
      type: 'blog'
    }
  },
  {
    path: '/news/:id',
    name: 'News',
    component: () => import('../views/Article/Article.vue'),
    props: {
      type: 'news'
    }
  },
  {
    path: '/events/:id',
    name: 'Event',
    component: () => import('../views/Article/Article.vue'),
    props: {
      type: 'events'
    }
  },
  {
    path: '/blog/:id',
    name: 'Blog',
    component: () => import('../views/Article/Article.vue'),
    props: {
      type: 'blog'
    }
  },*/
  {
    path: '/dashboard',
    name: 'DashboardMarket',
    component: () => import('../views/Dashboard/Market.vue'),
  },
  {
    path: '/dashboard/setting',
    name: 'DashboardSetting',
    component: () => import('../views/Dashboard/RobotsSetting.vue'),
  },
  /*{
    path: '/dashboard/overview',
    name: 'DashboardOverview',
    component: () => import('../views/Dashboard/Overview.vue'),
  },*/
  {
    path: '/dashboard/robots',
    name: 'DashboardRobotsList',
    component: () => import('../views/Dashboard/RobotsList.vue'),
  },
  {
    path: '/dashboard/plan',
    name: 'DashboardPlan',
    component: () => import('../views/Dashboard/Plan.vue'),
  },
  {
    path: '/dashboard-pro/',
    name: 'DBProOverview',
    component: () => import('../views/DashboardPro/Overview.vue')
  },
  {
    path: '/dashboard-pro/portfolios',
    name: 'DBProPortfolios',
    component: () => import('../views/DashboardPro/Portfolios.vue')
  },
  {
    path: '/dashboard-pro/portfolios/:id',
    name: 'DBProPortfoliosDetail',
    component: () => import('../views/DashboardPro/PortfoliosDetail.vue')
  },
  {
    path: '/dashboard-pro/exchange',
    name: 'DBProExchange',
    component: () => import('../views/DashboardPro/Exchange.vue')
  },
  {
    path: '/dashboard-pro/exchange/:id',
    name: 'DBProExchangeDetail',
    component: () => import('../views/DashboardPro/ExchangeDetail.vue')
  },
  {
    path: '/dashboard-pro/users',
    name: 'DBProUsers',
    component: () => import('../views/DashboardPro/Users.vue')
  },
  {
    path: '/email_verify/:uid/:vid',
    name: 'EmailVerify',
    component: () => import('../views/EmailVerify.vue'),
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue'),
  },
  {
    path: '/reset_password/:uid/:vid',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue'),
  },
  { path: '/404', 
    name: 'NotFound', 
    component: () => import('../views/404.vue'),
  },
  { path: '*', 
    redirect: '/404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    const dBProAdminOnly = ['DBProExchange', 'DBProExchangeDetail', 'DBProUsers']
    if (to.name.startsWith('Dashboard')) {
        if (!Cookies.get('dcbotLogin')) {
            if (Cookies.get('dcbotProLogin')) {
                next({ name: 'DBProOverview' })
            } else {
                next({ name: 'Home' })
            }
        } else {
            next() // go to wherever I'm going
        }
    } else if (to.name.startsWith('DBPro'))  {
        if (!Cookies.get('dcbotProLogin')) {
            if (Cookies.get('dcbotLogin')) {
                next({ name: 'DashboardMarket' })
            } else {
                next({ name: 'Home' })
            }
            next({ name: 'Home' })
        } else {
            let role = Cookies.get('dcbotLogin_role');
            if(role != 'admin' && dBProAdminOnly.some(ele => ele == to.name )){
                next({ name: 'DBProOverview' })
            }
            next()
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
})

export default router
