<template>
    <div class="input-unit">
        <h4 class="input-title">{{title}}</h4>
        <div v-if="loading" class="skeleton-loading input-loading"></div>
        <GlobalInput v-if="type=='text' || type=='number' || type=='password' || type=='date'" :type="type" :minVal="minVal" :maxVal="maxVal" :placeholder="placeholder" :classname="classname" :hasBtn="hasBtn" :btnText="btnText" :btnStyle="btnStyle" :value="value" v-model="inputVal" :disabled="disabled" :tooltip="tooltip"></GlobalInput>
        <select v-if="type=='select'" v-model="inputVal" :disabled="disabled" :class="checkIcon ? 'withIcon' : ''" :style="checkIcon ? `background-image: url(${ checkIcon }), url(${require(`../assets/images/arrow-down.svg`)});` : ``">
            <option v-for="(ele, ind) in selectOptions" :value="ele.value" :key="ind">{{ ele.title == 'UST' ? 'USDT' : ele.title }}</option>
        </select>
        <DatePicker v-if="type=='birthday'" v-model="inputVal" :input-attr="{name: ''}" :placeholder="placeholder" :disabled="disabled" />
        <textarea v-if="type=='textarea'" v-model="inputVal" :placeholder="placeholder" rows="5"></textarea>
        <vSelect v-if="type=='searchSelect'" :reduce="(option) => option.code" :options="selectOptions" v-model="inputVal" :placeholder="placeholder"></vSelect>
    </div>
</template>

<script>
import GlobalInput from '@/components/GlobalInput'
import DatePicker from 'vue2-datepicker';
import vSelect from "vue-select";

export default {
    name: 'InputUnit',
    components: {
        GlobalInput,
        DatePicker,
        vSelect
    },
    data (){
        return {
            selectVal: {
                type: String,
                default: ""
            },
        }
    },
    props: {
        title:{
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "text"
        },
        minVal: {
            type: Number,
            default: null
        },
        maxVal: {
            type: Number,
            default: null
        },
        selectOptions: {
            type: Array,
            default: () => [{
                title: "預設選項",
                value: "0"
            }]
        },
        placeholder: {
            type: String,
            default: ""
        },
        classname: {
            type: String,
            default: ""
        },
        hasBtn: {
            type: Boolean,
            default: false
        },
        btnText: {
            type: String,
            default: "Submit"
        },
        btnStyle: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        tooltip: {
            type: String,
            default: ""
        },
        loading: {
            type: Boolean,
            default: false
        }
    },computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        checkIcon: function(){
            let now = this.selectOptions.find(item => item.value === this.inputVal)
            if(now){
                return now.icon ? now.icon : false
            }else{
                return false
            }
        }
    }
}
</script>