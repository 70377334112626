<template>
    <div class="db-modal confirm-dialog">
        <div class="content">
            <div class="wrapper">
                <h4 class="title">{{ this.title }}</h4>
                <p class="content">{{ this.content }}</p>
            </div>
            <div class="btn-container">
                <button class="blue" @click="cancel()">取消</button>
                <button class="light" @click="agree()">確定</button>
            </div>
        </div>
    </div>
</template>

<script>

import $ from 'jquery';
export default {
    name: 'ConfirmDialog',
    components: {
    },
    data (){
        return {
            title: "",
            content: "",
            resolve: null,
            reject: null,
            close: () => {}
        }
    },
    methods: {
        openModal(title, content, close){
            this.title = title
            this.content = content
            this.close = close
            $('.confirm-dialog').fadeIn('300').css('display', 'flex');
            return new Promise((resolve, reject) => {
                this.resolve = resolve
                this.reject = reject
            })
        },
        agree(){
            this.resolve(true)
            $('.confirm-dialog').fadeOut('300', () => this.close())
        },
        cancel(){
            this.resolve(false)
            $('.confirm-dialog').fadeOut('300', () => this.close())
        }
    },
}
</script>