import ConfirmDialog from "./ConfirmDialog.vue";
import Vue from 'vue'
const install = (app) => {
    let container;
    let dialog;
    const close = ()=> {
        if (dialog) dialog.$destroy(container);
        container = document.querySelector('.db-modal.confirm-dialog');
        if (container) document.body.removeChild(container);
    }
    const confirm = (msg, content)=>{
        close();
        container = document.createElement('div');
        container.setAttribute('id','ConfirmDialogPlug');
        document.body.appendChild(container);
        dialog = new Vue(ConfirmDialog);
        dialog.$mount(container);
        return dialog.openModal(msg, content, close);
    }
    
    app.prototype.$confirm = confirm;
}

export default install;