import axios from 'axios';
import {err} from './utils.js';

const errorHandle = (status, msg) => {
    switch(status){
        default:
            if(msg !== undefined){
                err(msg);
            }
    }
}

var instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

instance.interceptors.request.use((config) => {
    config.withCredentials = true;
    config.headers['lang'] = localStorage.getItem('locale') || 'zh_HK'
    return config;
}, (error) => {
    return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const {response} = error;
    if(response){
        errorHandle(response.status, response.data.msg);
        return Promise.reject(error);
    }else{
        if(!window.navigator.onLine){
            err("網路錯誤，請稍後再試！");
        }else{
            return Promise.reject(error);
        }
    }
})

export default function (method, url, data=null){
    method = method.toLowerCase();
    if(method == 'post'){
        return instance.post(url, data)
    }else if(method == 'get'){
        return instance.get(url, {params: data})
    }else{
        console.log('unknown method:' + method);
        return false;
    }
}