<template>
    <div class="home">
        <div class="header-section">
            <div class="container">
                <div class="decos">
                    <img class="deco1" src="../assets/images/deco/red-deco.svg" alt="">
                    <img class="deco2" src="../assets/images/deco/blue-deco.svg" alt="">
                    <img class="deco3" src="../assets/images/deco/red-deco.svg" alt="">
                    <img class="deco4" src="../assets/images/deco/blue-deco.svg" alt="">
                </div>
                <img src="../assets/images/logo.svg" alt="" class="home-logo">
                <h1 class="slogan">{{ getData(data.header.slogan) }}</h1>
                <p class="description">{{ getData(data.header.description) }}</p>
                <div class="stats">
                    <div v-for="(ele, ind) in data.header.stats" :key="`stats-${ind}`" class="group">
                        <h1 class="num">{{ ele.number }}</h1>
                        <h4 class="title">{{ getData(ele.title) }}</h4>
                    </div>
                </div>
                <button class="new-light get-started" @click="getStarted()">{{ getData(data.header.cta) }}</button>
            </div>
        </div>
        <div class="preview-section">
            <div class="container">
                <h1 class="slogan">{{ getData(data.preview.slogan) }}</h1>
                <div class="preview-bg">
                    <div class="preview-header">
                        <div class="url">https://dcbot.ai/dashboard-pro</div>
                    </div>
                </div>
                <img src="../assets/images/dbpreview_home.svg" class="db-preview" >
            </div>
        </div>
        <div class="client-section">
            <div class="container">
                <h1 class="slogan">{{ getData(data.client.slogan) }}</h1>
                <p class="description">{{ getData(data.client.description) }}</p>
                <div class="clients">
                    <img src="../assets/images/clients/binance.png" alt="" class="client">
                    <img src="../assets/images/clients/bitfinex.png" alt="" class="client">
                    <img src="../assets/images/clients/bybit.png" alt="" class="client">
                    <img src="../assets/images/clients/bitget.png" alt="" class="client">
                    <img src="../assets/images/clients/kucoin.png" alt="" class="client">
                </div>
            </div>
        </div>
        <div class="what-we-provide-section">
            <div class="container">
                <h1 class="slogan">{{ getData(data.whatWeProvide.slogan) }}</h1>
                <div class="strategies">
                    <div v-for="(ele, ind) in data.whatWeProvide.strategies" :key="`startegy-${ind}`" class="strategy">
                        <img :src="ele.image" alt="" class="lending">
                        <h3 class="title">{{ getData(ele.title) }}</h3>
                        <div class="estimate">
                            <h2 class="num">{{ ele.performance }}</h2>
                            <p>{{ $t('home.estimatedAPY') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="why-us-section">
            <div class="container">
                <h1 class="slogan">{{ getData(data.whyus.slogan) }}</h1>
                <p class="description">{{ getData(data.whyus.description) }}</p>
                <div class="features">
                    <div v-for="(ele, ind) in data.whyus.features" :key="`feature-${ind}`" class="feature">
                        <img :src="ele.image" alt="" class="illustrate">
                        <h3>{{ getData(ele.title) }}</h3>
                        <p>{{ getData(ele.description) }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="our-team-section">
            <div class="container">
                <h1 class="slogan">{{ getData(data.meetOurTeam.slogan) }}</h1>
                <div class="teams">
                    <div v-for="(ele, ind) in data.meetOurTeam.members" :key="`member-${ind}`" class="member">
                        <img :src="ele.image" alt="">
                        <h3 class="name">{{ getData(ele.name) }}</h3>
                        <p class="description">{{ getData(ele.title) }}</p>
                        <ul>
                            <li v-for="(li, index) in ele.highlight" :key="`highlight-${index}`">{{ getData(li) }}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery';
import Cookies from 'js-cookie'

export default {
  name: 'Home',
  components: {
  },
  data() {
      return{
          data:{
            header: {
                slogan: {
                    en: "Maximize Results, Minimize Effort",
                    zh: "訂製專屬的量化投資組合來全方位挖掘市場機會"
                },
                description: {
                    en: "DCBot automates your trades with quantitative financial models and generates great performing returns.",
                    zh: ""
                },
                stats: [{
                    title: {
                        en: "Institutional & HNWI clients",
                        zh: "機構和高淨值客戶"
                    },
                    number: "100+"
                }, {
                    title: {
                        en: "Institutional & HNWI clients",
                        zh: "管理的資產"
                    },
                    number: "200M+"
                }, {
                    title: {
                        en: "Trading Volume",
                        zh: "交易量"
                    },
                    number: "6.8B+"
                }],
                cta: {
                    en: "Get Started",
                    zh: "聯絡我們"
                }
            },
            preview: {
                slogan: {
                    en: "Elevate Your Performance with Intuitive Tools",
                    zh: "透過直觀的工具，提升您的績效至新的高度"
                }
            },
            client: {
                slogan: {
                    en: "We work with leaders within our industry",
                    zh: "立刻加入 與專家為伍"
                },
                description: {
                    en: "Utilizing different platforms to achieve goals",
                    zh: "我們的合作夥伴都是產業中的領導者"
                }
            },
            whatWeProvide: {
                slogan: {
                    en: "What we provide",
                    zh: "我們提供的項目"
                },
                strategies: [{
                    title: {
                        en: "Lending Strategy",
                        zh: "AI智能放貸"
                    },
                    image: require("@/assets/images/strategies/lending.png"),
                    performance: "8% ~ 25%"
                }, {
                    title: {
                        en: "CTA Strategy",
                        zh: "CTA趨勢交易"
                    },
                    image: require("@/assets/images/strategies/cta.png"),
                    performance: "25% ~ 50%"
                }, {
                    title: {
                        en: "Arbitrage Strategy",
                        zh: "跨期套利交易"
                    },
                    image: require("@/assets/images/strategies/arbitrage.png"),
                    performance: "25% ~ 30%"
                }, {
                    title: {
                        en: "Cross-Exchange Arbitrage Strategy",
                        zh: "跨交易所套利"
                    },
                    image: require("@/assets/images/strategies/cross-exchange.png"),
                    performance: "15% ~ 30%"
                }, {
                    title: {
                        en: "Statistical Arbitrage Strategy",
                        zh: "統計套利"
                    },
                    image: require("@/assets/images/strategies/static.png"),
                    performance: "40% ~ 80%"
                }, {
                    title: {
                        en: "And More...",
                        zh: "還有更多...."
                    },
                    image: require("@/assets/images/strategies/more.png"),
                    performance: "+ ???%"
                }]
            },
            whyus: {
                slogan: {
                    en: "Why do I need to use DCBot?",
                    zh: "為甚麼要用DCBot智能投顧來投資加密貨幣?"
                },
                description: {
                    en: "Our strategies enable our clients to maximize their growth",
                    zh: "我們的業務範圍可以多方面兼顧到讓您利益最大化的方式"
                },
                features: [{
                    title: {
                        en: "24/7 automated trading to capture market trends",
                        zh: "24小時無休全自動交易，隨時掌握市場動態"
                    },
                    image: require("@/assets/images/automating.png"),
                    description: {
                        en: "",
                        zh: ""
                    }
                }, {
                    title: {
                        en: "Using AI strategies to increase stable profit",
                        zh: "AI智能交易策略，捕捉穩定獲利的機會"
                    },
                    image: require("@/assets/images/capturing.png"),
                    description: {
                        en: "",
                        zh: ""
                    }
                }, {
                    title: {
                        en: "Customer assets safety is our first priority",
                        zh: "資金安全第一，客戶資產有保障"
                    },
                    image: require("@/assets/images/strategies.png"),
                    description: {
                        en: "",
                        zh: ""
                    }
                }]
            },
            meetOurTeam: {
                slogan: {
                    en: "Our team",
                    zh: "我們的專業團隊"
                },
                members: [{
                    name: {
                        en: "Joey Zhang",
                        zh: "Joey Zhang"
                    },
                    title: {
                        en: "Founder & CEO",
                        zh: "創辦人 & 執行長"
                    },
                    image: require("@/assets/images/team_avatar/joey.png"),
                    highlight: [{
                        en: "Bachelor of Finance from the School of Economics and Management, Tsinghua University",
                        zh: "清華大學經管學院金融學學士、清華五道口金融EMBA"
                    }, {
                        en: "EMBA from PBC School of Finance, Tsinghua University",
                        zh: "2005-2015年擔任高盛（亞洲）執行董事，管理資產規模達數十億美元"
                    }, {
                        en: "From 2005 to 2015, served as an Executive Director at Goldman Sachs (Asia)",
                        zh: "連續十年所管理資產全部獲得正收益"
                    }]
                }, {
                    name: {
                        en: "Eric Wang",
                        zh: "Eric Wang"
                    },
                    title: {
                        en: "Product Director",
                        zh: "產品總監"
                    },
                    image: require("@/assets/images/team_avatar/eric.png"),
                    highlight: [{
                        en: "Master's in Management from National Central University",
                        zh: "中央大學管理學碩士"
                    }, {
                        en: "Responsible for market-making trading on the Hong Kong Exchange, Singapore Exchange, and Taiwan Futures Exchange",
                        zh: "負責香港交易所、新加坡交易所、台灣期貨交易所做市商交易"
                    }, {
                        en: "Has extensive technical and product design experience in areas such as blockchain, smart contracts, financial engineering, and quantitative trading",
                        zh: "在區塊鏈、智能合約、金融工程與量化交易等，具備豐富的技術與產品設計經驗"
                    }]
                }, {
                    name: {
                        en: "Richard",
                        zh: "Richard"
                    },
                    title: {
                        en: "Investment Director",
                        zh: "投資總監"
                    },
                    image: require("@/assets/images/team_avatar/richard.png"),
                    highlight: [{
                        en: "Co-founder of Big-Fish Finance",
                        zh: "新格網路科技（深圳）有限公司董事長、 大魚金融聯合創始人"
                    }, {
                        en: "Master's in Financial Engineering from Tsinghua University",
                        zh: "清華大學金融碩士（金融工程）"
                    }]
                }]
            }
          },
          Cookies
          //scrollPosition: 0
      }
  },
  props: {
      rnd: {
          type: String,
          default: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
      }
  },
  methods: {
        to(url){
            window.location = url
        },
        trynow(){
            if(Cookies.get('dcbotLogin')){
                window.location = "/dashboard"
            }else{
                $('.login-modal').fadeIn(300).css('display', 'flex');
                $('.form-container').removeClass('active');
                $('.form-container.login').addClass('active');
                $('.toggle-container > h2').removeClass('active');
                $('.toggle-container > h2[data-target=login]').addClass('active');
            }
        },
        getData(obj){
            return this.$i18n.locale === 'zh_HK' ? obj.zh : obj.en
        },
        rndNewPos(){
            let movement = 400
            for(let i = 1; i <= 4; i++){
                let target = $(`.deco${i}`)
                let moveLeft = Math.floor(Math.random() * (movement * 2 + 1) + (0 - movement))
                let moveTop = Math.floor(Math.random() * (movement * 2 + 1) + (0 - movement))
                let newScale = Math.random() * (3 - 0.3) + 0.3
                target.css('transform', `translate( ${moveLeft}px, ${moveTop}px) scale(${newScale})`)
            }
        },
        handleScrollAnimation(){
            let vh = window.innerHeight;

            // preview
            let previewPos = $('.db-preview').offset().top - (vh * 0.7)
            if(window.scrollY > previewPos){
                $('.db-preview').addClass('active')
            }else{
                $('.db-preview').removeClass('active')
            }
        },
        getStarted(){
            window.open('https://t.me/DerivativesCN', '_blank').focus();
        }
  },
  mounted() {
    this.rndNewPos()
    setInterval(this.rndNewPos, 3000)
    window.addEventListener('scroll', this.handleScrollAnimation)
  },
}
</script>
