import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const messages = { // 引入翻译文件
  zh_HK: require('./zh-HK.json'),
  en_US: require('./en-US.json')
}
export const getBrowserLang = function() {
    let browserLang = navigator.language
        ? navigator.language
        : navigator.browserLanguage;
    let defaultBrowserLang = "";
    if (
        browserLang.toLowerCase() === "us" ||
        browserLang.toLowerCase() === "en" ||
        browserLang.toLowerCase() === "en-us"
    ) {
        defaultBrowserLang = "en_US";
    } else {
        defaultBrowserLang = "zh_HK";
    }
    localStorage.setItem('locale', defaultBrowserLang)
    return defaultBrowserLang;
};
const locale_s = localStorage.getItem('locale')
const i18n = new VueI18n({
  locale: locale_s ? locale_s : getBrowserLang(),
  messages
})
export default i18n