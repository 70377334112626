import Vue from 'vue'
import 'bootstrap-vue';
import App from './App.vue'
import router from './router'
import i18n from './locales/i18n'

// toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// confirm dialog
import ConfirmDialog from "./components/ConfirmDialog.js";

// moment
import moment from 'moment'

// bootstrap
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import "vue-select/dist/vue-select.css";

// custom scss
import './assets/global.scss';
import './assets/datepicker.scss';
import './assets/globalInput.scss';
import './assets/switcher.scss';
import './assets/joinusSection.scss';
import './assets/derivativesSection.scss';
import './assets/nav.scss';
import './assets/footer.scss';
import './assets/homeNew.scss';
import './assets/about.scss';
import './assets/product.scss';
import './assets/article.scss';
import './assets/articleslist.scss';
import './assets/dashboardNav.scss';
import './assets/robotsList.scss';
import './assets/robotsSetting.scss';
import './assets/overview.scss';
import './assets/market.scss';
import './assets/plan.scss';
import './assets/dashboard-pro/overview.scss';
import './assets/dashboard-pro/portfolios.scss';
import './assets/dashboard-pro/portfiolioDetail.scss';
import './assets/dashboard-pro/exchangeDetail.scss';
import './assets/exchangeAccountsTable.scss';
import './assets/usersTable.scss';
import './assets/accountActivitiesTable.scss';
import './assets/notfound.scss';
import './assets/emailVerify.scss';
import './assets/resetpassword.scss';

Vue.config.productionTip = false

Vue.use(Toast);
Vue.use(ConfirmDialog);

Vue.filter('dateFormat', function (daraStr, pattern = 'MMM DD, YYYY') {
    return moment(daraStr).format(pattern)
  })

Vue.filter('recordDateFormat', function (daraStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(daraStr).format(pattern)
})

Vue.filter('rate', function (num, limit = -1, toPercent = true) {
    if(num == undefined)return limit == -1 ? 0 : (0).toFixed(limit)
    return limit == -1 ? num*(toPercent * 99 + 1) : (num*(toPercent * 99 + 1)).toFixed(limit)
})

Vue.filter('currency', function(num, limit = 2, withoutShorthand = false){
    if(num == undefined)return limit == -1 ? 0 : (0).toFixed(limit)
    if(withoutShorthand)return addSeperator(num.toFixed(limit))
    return formatMoney(num, limit)
})

new Vue({/*
    created() {
        window.gapi.load('auth2', () => {
            window.gapi.auth2.init({
              client_id: '971698087521-batbnqvt3dg9h37451pqerp58f3b9fc6.apps.googleusercontent.com',
            });
        });
    },*/
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')

function formatMoney(num, fixed = 2){
    const sign = Number(num) >= 0 ? '' : '-'
    const number = Math.abs(Number(num))
    if(number == undefined)return '0'
    if(number >= 1000000000){
        return sign + addSeperator((number/1000000000).toFixed(fixed)) + 'B'
    }else if(number >= 1000000){
        return sign + addSeperator((number/1000000).toFixed(fixed)) + 'M'
    }else if(number >= 1000){
        return sign + addSeperator((number/1000).toFixed(fixed)) + 'K'
    }else{
        return sign + addSeperator(number.toFixed(fixed))
    }
}

function addSeperator(num){
    return Math.floor(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (num.toString().split('.').length > 1 ? `.${num.toString().split('.')[1]}` : '')
    //const deci = (number - number.toFixed(0)).toString()
}
