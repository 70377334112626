<template>
    <div :class="`global-input ${disabled ? 'disabled' : ''}`" :data-tip="tooltip">
        <input v-bind:type="type" v-bind:placeholder="placeholder" v-bind:class="classname" v-bind:min="minVal" v-bind:max="maxVal" v-model="inputVal" :disabled="disabled" />
        <button v-bind:class="btnStyle" v-if="hasBtn">{{ btnText }}</button>
    </div>
</template>

<script>
export default {
  name: 'GlobalInput',
  props: {
    type: {
        type: String,
        default: "text"
    },
    minVal: {
        type: Number,
        default: null
    },
    maxVal: {
        type: Number,
        default: null
    },
    placeholder: {
        type: String,
        default: ""
    },
    classname: {
        type: String,
        default: ""
    },
    hasBtn: {
        type: Boolean,
        default: false
    },
    btnText: {
        type: String,
        default: "Submit"
    },
    btnStyle: {
        type: String,
        default: ""
    },
    value: {
        type: String,
        default: ""
    },
    disabled: {
        type: Boolean,
        default: false
    },
    tooltip: {
        type: String,
        default: ""
    },
  },computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  }
}
</script>