<template>
  <div>
    <button id="googleLoginBtn" class="detach login-with-google">{{$t("form.LoginWithGoogle")}}</button>
  </div>
</template>

<script>
export default {
    name: 'GoogleLoginButton',
    props: {
        width: {
            type: String,
            default: null
        },
        height: {
            type: String,
            default: null
        }
    },
    mounted() {
        var _self = this;
        window.gapi.load('auth2', () => {
            window.gapi.auth2.init({
              client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
              scope: 'profile email'
            }).then(() => {
                var auth2 = window.gapi.auth2.getAuthInstance();
                auth2.attachClickHandler(document.getElementById('googleLoginBtn'), {},
                    function(googleUser) {
                        _self.signIn(googleUser)
                    }, function(error) {
                        console.log(error)
                    }
                )
            })
        });
        /*
        window.gapi.signin2.render('google-sign-in-button', {
            scope: 'profile email',
            longtitle: true,
            theme: 'light',
            onsuccess: this.signIn,
            onfailure: () => {}
        });*/

        
    },
    methods: {
        signIn(googleUser) {
            const id_token = googleUser.getAuthResponse().id_token;
            this.$emit('sign-in', id_token);
        }
    }
};
</script>