<template>
    <div id="app" :style="isDashboard ? 'background-color: #EEF3F8;min-height: 100vh;' : ''">
        <div v-if="!this.noLoading.includes(this.$route.name)" class="global-loading">
            <img v-bind:src="`${require(`./assets/images/loading.svg`)}?${rnd}`" alt="">
        </div>
        <div class="login-modal">
            <div class="content">
                <div class="toggle-container">
                    <h2 class="active" data-target="login">{{ $t('header.login') }}</h2><h2 v-if="false" data-target="regis">{{ $t('header.register') }}</h2>
                    <h2 class="result-title">{{$t('form.registerSuccess')}}</h2>
                </div>
                <div class="form-container login active">
                    <InputUnit v-if="false" type="select" :title="$t('form.userGroup')" :selectOptions="loginTypeOptions" :hasBtn="false" class="" v-model="loginType" />
                    <h4>{{ $t('form.userName') }}</h4>
                    <GlobalInput type="text" :placeholder="$t('text.pleaseInputYourUserName')" :hasBtn="false" class="" v-model="loginData.username"></GlobalInput>
                    <h4>{{ $t('form.password') }}</h4>
                    <GlobalInput type="password" :placeholder="$t('text.pleaseInputYourPwd')" :hasBtn="false" class="" v-model="loginData.password"></GlobalInput>
                    <a href="/forgot" class="forgot-password-link">{{ $t('form.forgetPwd') }}</a>
                    <button class="login-confirm light" @click="handleLogin()">{{ $t('header.login') }}</button>
                </div>
                <div class="form-container regis">
                    <h4>{{ $t('form.email') }}</h4>
                    <GlobalInput type="text" :placeholder="$t('text.pleaseInputYourEmail')" :hasBtn="false" v-model="regisData.username"></GlobalInput>
                    <h4>{{ $t('form.password') }}</h4>
                    <GlobalInput type="password" :placeholder="$t('text.atLeastEightDigit')" :hasBtn="false" v-model="regisData.password"></GlobalInput>
                    <h4>{{ $t('form.confirmPwd') }}</h4>
                    <GlobalInput type="password" :placeholder="$t('text.pleaseConfirmYourPwd')" :hasBtn="false" v-model="regisData.repeatPassword"></GlobalInput>
                    <button class="regis-confirm light" @click="handleRegis()">{{ $t('header.register') }}</button>
                </div>
                <div class="form-container tfa">
                    <h4>Google 2FA Code</h4>
                    <GlobalInput type="text" :placeholder="$t('text.pleaseInputGoogle2faCode')" :hasBtn="false" v-model="auth_code"></GlobalInput>
                    <button class="tfa-confirm light" @click="handleTfa()">{{ $t('form.submit') }}</button>
                </div>
                <div class="social-login-div" v-if="loginType == 'individual'"><span>{{ $t('text.or') }}</span></div>
                <div class="social-login" v-if="loginType == 'individual'">
                    <GoogleLoginButton class="login-with-google" @sign-in="oAuthSignIn('google', $event)"></GoogleLoginButton>
                    <!--
                    <button class="detach login-with-fb" @click="loginTest()">使用Facebook登入</button>
                    -->
                </div>
                <div class="form-container regis-success">
                    <p>請至您的電子信箱進行驗證，以完成註冊。</p>
                    <p>視窗將於 5 秒後關閉...</p>
                </div>
            </div>
        </div>
        <div v-if="!isDashboard" id="nav" :class="`global-nav${useLightNav.includes($route.name) ? ' light' : ''}${$route.name == 'Home' ? ' home' : ''}`">
            <div class="container">
                <a href="/"><img class="logo" src="./assets/images/logo.svg" /></a>
                <a href="/"><img class="logo-light" src="./assets/images/logo-light.svg" /></a>
                <div class="link-container">
                    <div v-if="false">{{ $t('header.service') }}
                        <ul>
                            <li v-for="(el, index) in this.products" :key="index"><a :href="`/product/${el._id}`">{{el.name}}</a></li>
                        </ul>
                    </div>
                    <div v-if="false">{{ $t('header.media') }}
                        <ul>
                            <li><a href="/news">{{ $t('header.news') }}</a></li>
                            <li v-if="false"><a href="/events">{{ $t('header.events') }}</a></li>
                            <li><a href="/blog">{{ $t('header.blog') }}</a></li>
                        </ul>
                    </div>
                    <div><a href="https://twitter.com/DerivativesCN" target="_blank">Twitter</a></div>
                    <div><a href="https://youtube.com/@dcbotai9449" target="_blank">Youtube</a></div>
                    <div><a href="https://medium.com/@DerivativesCN" target="_blank">Medium</a></div>
                    <div><a href="https://t.me/DerivativesCN" target="_blank">Telegram</a></div>
                    <div v-if="false"><a href="/about">{{ $t('header.aboutUs') }}</a></div>
                    <div><a @click="changeLocale()">中文/ENG</a></div>
                </div>
                <div class="btn-container" v-if="!this.Cookies.get('dcbotLogin') && !this.Cookies.get('dcbotProLogin')">
                    <button class="login-btn new-light">{{ $t('header.login') }}</button>
                    <button v-if="false" class="regis-btn light">{{ $t('header.register') }}</button>
                </div>
                <div :class="`btn-container login ${useLightNav.includes($route.name) ? 'light' : ''}`" v-if="this.Cookies.get('dcbotLogin') || this.Cookies.get('dcbotProLogin')">
                    <h4>{{ this.Cookies.get('dcbotLogin_uname').split('@')[0]}}</h4>
                    <div class="user-menu">
                        <div class="content">
                            <a @click="handleLogout()">{{$t('header.logout')}}</a>
                            <a :href="this.Cookies.get('dcbotLogin') ? '/dashboard' : '/dashboard-pro'">{{$t('header.dashboard')}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="`container mobile ${this.mobileExpandOutsideNav ? 'active' : ''}`">
                <svg :class="`menu${useLightNav.includes($route.name) ? ' light' : ''}`" @click="toggleOutsideMenu('open')" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="2" y="5" width="20" height="2" fill="white"/>
                    <rect x="2" y="17" width="20" height="2" fill="white"/>
                    <rect x="2" y="11" width="20" height="2" fill="white"/>  
                </svg>
                <a href="/"><img class="logo" :src="require(`./assets/images/logo${useLightNav.includes($route.name) ? '-light' : ''}.svg`)" /></a>
                <div class="btn-container" v-if="!this.Cookies.get('dcbotLogin') && !this.Cookies.get('dcbotProLogin')">
                    <button class="login-btn new-light">{{ $t('header.login') }}</button>
                </div>
                <div v-if="this.Cookies.get('dcbotLogin') || this.Cookies.get('dcbotProLogin')" :class="`account${useLightNav.includes($route.name) ? ' light' : ''}`" @click="showUserMenu = !showUserMenu">
                    <div v-if="showUserMenu" class="mobile-user-menu">
                        <div class="content">
                            <a @click="handleLogout()">{{ $t('header.logout') }}</a>
                            <a :href="this.Cookies.get('dcbotLogin') ? '/dashboard' : '/dashboard-pro'">{{$t('header.dashboard')}}</a>
                        </div>
                    </div>
                </div>
                <div class="mobile-menu-overlay" @click="toggleOutsideMenu('close')"></div>
                <div class="mobile-menu">
                    <img src="./assets/images/close-thin.svg" alt="" class="close-menu" @click="toggleOutsideMenu('close')">
                    <!--
                    <div class="link-container">
                        <h4 class="title">{{ $t('header.service') }}</h4>
                        <a v-for="(el, index) in this.products" :key="index" :href="`/product/${ el._id }`" class="link">{{el.name}}</a>
                    </div>
                    <div class="link-container">
                        <h4 class="title">{{ $t('header.media') }}</h4>
                        <a href="/news" class="link">{{ $t('header.news') }}</a>
                        <a v-if="false" href="/events" class="link">{{ $t('header.events') }}</a>
                        <a href="/blog" class="link">{{ $t('header.blog') }}</a>
                    </div>
                    <div class="link-container">
                        <h4 class="title">{{ $t('header.aboutUs') }}</h4>
                        <a href="/about" class="link">{{ $t('header.aboutUs') }}</a>
                    </div> -->
                    <div class="link-container">
                        <h4 class="title">{{ $t('header.social') }}</h4>
                        <a href="https://twitter.com/DerivativesCN" target="_blank">Twitter</a>
                        <a href="https://youtube.com/@dcbotai9449" target="_blank">Youtube</a>
                        <a href="https://medium.com/@DerivativesCN" target="_blank">Medium</a>
                        <a href="https://t.me/DerivativesCN" target="_blank">Telegram</a>
                    </div>
                    <div class="link-container">
                        <h4 class="title">{{ $t('header.lang') }}</h4>
                        <a @click="changeLocale()">中文/ENG</a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isDashboard" class="dashboard-nav">
            <svg class="menu-toggle" @click="toggleMenu()" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="5" width="20" height="2" fill="#1A1739"/>
                <rect x="2" y="17" width="20" height="2" fill="#1A1739"/>
                <rect x="2" y="11" width="20" height="2" fill="#1A1739"/>
            </svg>
            <a href="/dashboard"><img class="logo" src="./assets/images/logo-light.svg" /></a>
            <div class="username">
                <h4>{{this.Cookies.get('dcbotLogin_uname').split('@')[0]}}</h4>
                <div class="user-menu">
                    <div class="content">
                        <a @click="handleLogout()">{{ $t('header.logout') }}</a>
                        <a href="/">{{ $t('header.home') }}</a>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isDashboard" :class="`dashboard-sidebar${this.expandNav ? '' : ' small'}${this.mobileExpandNav ? ' active' : ''}`">
            <router-link v-if="!isDashboardPro" to="/dashboard" :class="`item${$route.name == 'DashboardMarket' ? ' active' : ''}`">
                <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 16.391L9.09677 9.23818L14 14.8341L22 7" stroke="#6A859E" stroke-width="2"/>
                    <path d="M16 7H22V13" stroke="#6A859E" stroke-width="2"/>
                </svg>
                <h4 class="title">{{$t('header.market')}}</h4>
            </router-link>
            <router-link v-if="!isDashboardPro" to="/dashboard/robots" :class="`item${$route.name == 'DashboardRobotsList' ? ' active' : ''}`">
                <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.0488 5C20.551 5 21 5.42429 21 6L21 18C21 18.5757 20.551 19 20.0488 19L3.95122 19C3.44901 19 3 18.5757 3 18L3 6C3 5.42429 3.44901 5 3.95122 5L20.0488 5Z" stroke="white" stroke-width="2"/>
                    <rect x="8" y="9" width="2" height="2" fill="white"/>
                    <rect x="14" y="9" width="2" height="2" fill="white"/>
                    <rect x="8" y="13" width="8" height="2" fill="white"/>
                </svg>
                <h4 class="title">{{$t('header.myBot')}}</h4>
            </router-link>
            <!--
            <router-link to="/dashboard/overview" :class="`item${$route.name == 'DashboardOverview' ? ' active' : ''}`">
                <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 17C21 15.8181 20.7672 14.6478 20.3149 13.5558C19.8626 12.4639 19.1997 11.4718 18.364 10.636C17.5282 9.80031 16.5361 9.13738 15.4442 8.68508C14.3522 8.23279 13.1819 8 12 8C10.8181 8 9.64778 8.23279 8.55585 8.68508C7.46392 9.13738 6.47177 9.80031 5.63604 10.636C4.80031 11.4718 4.13738 12.4639 3.68508 13.5558C3.23279 14.6478 3 15.8181 3 17" stroke="#6A859E" stroke-width="2"/>
                    <rect x="11" y="16" width="2" height="2" fill="#6A859E"/>
                    <rect x="11" y="6" width="2" height="4" fill="#6A859E"/>
                    <path d="M12 17L16 12" stroke="#6A859E" stroke-width="1.5"/>
                </svg>
                <h4 class="title">{{$t('header.overview')}}</h4>
            </router-link>
            -->
            <router-link v-if="!isDashboardPro" to="/dashboard/setting" :class="`item${$route.name == 'DashboardSetting' ? ' active' : ''}`">
                <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 6H22" stroke="#6A859E" stroke-width="2"/>
                    <path d="M7 3L7 9" stroke="#6A859E" stroke-width="2"/>
                    <path d="M17 9L17 15" stroke="#6A859E" stroke-width="2"/>
                    <path d="M10 15L10 21" stroke="#6A859E" stroke-width="2"/>
                    <path d="M2 12H22" stroke="#6A859E" stroke-width="2"/>
                    <path d="M2 18H22" stroke="#6A859E" stroke-width="2"/>
                </svg>
                <h4 class="title">{{$t('header.setting')}}</h4>
            </router-link>
            <router-link v-if="!isDashboardPro && false" to="/dashboard/plan" :class="`item${$route.name == 'DashboardPlan' ? ' active' : ''}`">
                <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.2439 3C19.3145 3 19.4793 3.03938 19.6674 3.30703C19.8533 3.57176 20 3.9904 20 4.5L20 19.5C20 20.0096 19.8533 20.4282 19.6674 20.693C19.4793 20.9606 19.3145 21 19.2439 21L4.7561 21C4.68546 21 4.52065 20.9606 4.33264 20.693C4.14669 20.4282 4 20.0096 4 19.5L4 4.5C4 3.9904 4.14669 3.57176 4.33264 3.30703C4.52065 3.03938 4.68546 3 4.7561 3L19.2439 3Z" stroke="#6A859E" stroke-width="2"/>
                    <rect x="7" y="6" width="6" height="2" fill="#6A859E"/>
                    <rect x="7" y="10" width="10" height="2" fill="#6A859E"/>
                    <rect x="7" y="14" width="8" height="2" fill="#6A859E"/>
                </svg>
                <h4 class="title">{{$t('header.subscription')}}</h4>
            </router-link>
            <router-link v-if="isDashboardPro" to="/dashboard-pro" :class="`item${$route.name == 'DBProOverview' ? ' active' : ''}`">
                <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21 17C21 15.8181 20.7672 14.6478 20.3149 13.5558C19.8626 12.4639 19.1997 11.4718 18.364 10.636C17.5282 9.80031 16.5361 9.13738 15.4442 8.68508C14.3522 8.23279 13.1819 8 12 8C10.8181 8 9.64778 8.23279 8.55585 8.68508C7.46392 9.13738 6.47177 9.80031 5.63604 10.636C4.80031 11.4718 4.13738 12.4639 3.68508 13.5558C3.23279 14.6478 3 15.8181 3 17" stroke="#6A859E" stroke-width="2"/>
                    <rect x="11" y="16" width="2" height="2" fill="#6A859E"/>
                    <rect x="11" y="6" width="2" height="4" fill="#6A859E"/>
                    <path d="M12 17L16 12" stroke="#6A859E" stroke-width="1.5"/>
                </svg>
                <h4 class="title">{{$t('header.dashboard')}}</h4>
            </router-link>
            <router-link v-if="isDashboardPro" to="/dashboard-pro/portfolios" :class="`item${$route.name == 'DBProPortfolios' || $route.name == 'DBProPortfoliosDetail' ? ' active' : ''}`">
                <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.2439 3C19.3145 3 19.4793 3.03938 19.6674 3.30703C19.8533 3.57176 20 3.9904 20 4.5L20 19.5C20 20.0096 19.8533 20.4282 19.6674 20.693C19.4793 20.9606 19.3145 21 19.2439 21L4.7561 21C4.68546 21 4.52065 20.9606 4.33264 20.693C4.14669 20.4282 4 20.0096 4 19.5L4 4.5C4 3.9904 4.14669 3.57176 4.33264 3.30703C4.52065 3.03938 4.68546 3 4.7561 3L19.2439 3Z" stroke="#6A859E" stroke-width="2"/>
                    <rect x="7" y="6" width="6" height="2" fill="#6A859E"/>
                    <rect x="7" y="10" width="10" height="2" fill="#6A859E"/>
                    <rect x="7" y="14" width="8" height="2" fill="#6A859E"/>
                </svg>
                <h4 class="title">{{$t('header.portfolios')}}</h4>
            </router-link>
            <router-link v-if="isDashboardPro && Cookies.get('dcbotLogin_role') == 'admin'" to="/dashboard-pro/exchange" :class="`item${$route.name == 'DBProExchange' || $route.name == 'DBProExchangeDetail' ? ' active' : ''}`">
                <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 12V6C19 4.34315 17.6569 3 16 3H8C6.34315 3 5 4.34315 5 6V6" stroke="#6A859E" stroke-width="2" stroke-linecap="round"/>
                    <path d="M5 12L5 18C5 19.6569 6.34315 21 8 21L16 21C17.6569 21 19 19.6569 19 18V18" stroke="#6A859E" stroke-width="2" stroke-linecap="round"/>
                    <path d="M21.1492 11H16.8508C15.9055 11 15.4879 12.1904 16.2261 12.7809L18.3753 14.5002C18.7405 14.7924 19.2595 14.7924 19.6247 14.5002L21.7739 12.7809C22.5121 12.1904 22.0945 11 21.1492 11Z" fill="#6A859E"/>
                    <path d="M2.85078 13L7.14922 13C8.0945 13 8.51205 11.8096 7.77391 11.2191L5.6247 9.49976C5.25948 9.20758 4.74052 9.20758 4.37531 9.49976L2.22609 11.2191C1.48795 11.8096 1.9055 13 2.85078 13Z" fill="#6A859E"/>
                </svg>
                <h4 class="title">{{$t('header.exchange')}}</h4>
            </router-link>
            <router-link v-if="isDashboardPro && false" to="/dashboard-pro/strategies" :class="`item${$route.name == 'DBProStrategies' ? ' active' : ''}`">
                <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 16.391L9.09677 9.23818L14 14.8341L22 7" stroke="#6A859E" stroke-width="2"/>
                    <path d="M16 7H22V13" stroke="#6A859E" stroke-width="2"/>
                </svg>
                <h4 class="title">{{$t('header.strategies')}}</h4>
            </router-link>
            <router-link v-if="isDashboardPro && false" to="/dashboard-pro/monitors" :class="`item${$route.name == 'DBProMonitors' ? ' active' : ''}`">
                <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.1712 6.28935C7.70238 6.02929 10.324 4.66674 11.7919 3.53185L11.1802 2.74073L11.7919 3.53185C11.9004 3.44799 12.0996 3.44799 12.2081 3.53185L12.8127 2.74988L12.2081 3.53185C13.676 4.66674 16.2976 6.02929 19.8288 6.28935C19.8983 6.29447 20.0075 6.37053 19.9996 6.52862C19.8747 9.03549 19.3625 12.761 18.0717 15.8245C16.7681 18.9182 14.8356 21 12 21C9.16444 21 7.23187 18.9182 5.92828 15.8245C4.63748 12.761 4.12532 9.03549 4.00041 6.52862C3.99253 6.37053 4.10172 6.29447 4.1712 6.28935L4.09775 5.29205L4.1712 6.28935Z" stroke="#6A859E" stroke-width="2"/>
                    <path d="M9 12.5L10.4524 14.618C10.8805 15.2423 11.8202 15.183 12.1664 14.5098L15 9" stroke="#6A859E" stroke-width="2" stroke-linecap="round"/>
                </svg>
                <h4 class="title">{{$t('header.monitors')}}</h4>
            </router-link>
            <router-link v-if="isDashboardPro && Cookies.get('dcbotLogin_role') == 'admin'" to="/dashboard-pro/users" :class="`item${$route.name == 'DBProUsers' ? ' active' : ''}`">
                <svg class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="8" r="5" stroke="#6A859E" stroke-width="2"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.05547 11.6981C5.87829 11.4139 5.53151 11.263 5.23122 11.4113C3.90926 12.0641 3 13.4259 3 15V20C3 20.5523 3.44772 21 4 21C4.55228 21 5 20.5523 5 20V15C5 13.8954 5.89543 13 7 13V13C7.03695 13 7.05539 12.955 7.02938 12.9288C6.66086 12.5572 6.33373 12.1444 6.05547 11.6981ZM16.9706 12.9288C16.9446 12.955 16.9631 13 17 13V13C18.1046 13 19 13.8954 19 15V20C19 20.5523 19.4477 21 20 21C20.5523 21 21 20.5523 21 20V15C21 13.4259 20.0907 12.0641 18.7688 11.4113C18.4685 11.263 18.1217 11.4139 17.9445 11.6981C17.6663 12.1444 17.3391 12.5572 16.9706 12.9288Z" fill="#6A859E"/>
                </svg>
                <h4 class="title">{{$t('header.users')}}</h4>
            </router-link>
        </div>
        <div class="dashboard-overlay" @click="toggleMenu('close')"></div>
        <router-view :class="isDashboard&&this.expandNav ? 'expandNav' : ''"/>
        <div v-if="!isDashboard" class="section global-footer gray-bg">
            <div class="container content">
                <div class="decos">
                    <img class="deco1" src="./assets/images/deco/red-deco.svg" alt="">
                    <img class="deco2" src="./assets/images/deco/blue-deco.svg" alt="">
                    <img class="deco3" src="./assets/images/deco/red-deco.svg" alt="">
                    <img class="deco4" src="./assets/images/deco/blue-deco.svg" alt="">
                </div>
                <img src="./assets/images/logo.svg" alt="" class="logo">
                <h1 class="slogan">{{ $t('footer.slogan') }}</h1>
                <p class="description">{{ $t('footer.description') }}</p>
                <button class="new-light contact-us" @click="getStarted()">{{ $t('home.getStarted') }}</button>
                <div class="socials">
                    <a href="https://twitter.com/DerivativesCN" target="_blank" class="social-link"><img src="./assets/images/social/twitter-white.svg" alt=""></a>
                    <a href="https://medium.com/@DerivativesCN" target="_blank" class="social-link"><img src="./assets/images/social/medium-white.svg" alt=""></a>
                    <a href="https://t.me/DerivativesCN" target="_blank" class="social-link"><img src="./assets/images/social/telegram-white.svg" alt=""></a>
                </div>
                <div class="copyright">
                    <p>{{ this.copywriting.copyright }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import Cookies from 'js-cookie'
import md5 from 'js-md5'
import GlobalInput from '@/components/GlobalInput';
import InputUnit from '@/components/InputUnit';
import GoogleLoginButton from '@/components/GoogleLoginButton';
import {apiRegis, apiLogin, apiLogout, apiGoogleLogin} from '@/apis/account.js';
import {apiLoginPro, apiTfa} from '@/apis/pro/user.js';
import {apiProducts} from '@/apis/products.js';
import {apiGetHomeConfig} from '@/apis/copywriting.js';
import {MD5, verifyToken} from '@/utils/Encryption.js';

export default {
    components: {
        GlobalInput,
        GoogleLoginButton,
        InputUnit,
    },props: {
        noLoading: {
            type: Array,
            default: () => ["EmailVerify", "ForgotPassword", "ResetPassword"]
        },
        needLoading: {
            type: Array,
            default: () => ["NewsList", "EventsList", "BlogList", "Product" ]
        },useLightNav: {
            type: Array,
            default: () => ["Blog", "Event", "News", "Product", "About", "NotFound", "EmailVerify", "ForgotPassword", "ResetPassword"]
        },rnd: {
            type: String,
            default: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
        }
    },
    data() {
        return{
            regisData: {
                username: '',
                password: '',
                repeatPassword: ''
            }, loginData: {
                username: '',
                password: ''
            }, products:[]
            , Cookies
            , expandNav: true
            , mobileExpandNav: false
            , mobileExpandOutsideNav: false
            , showUserMenu: false
            , copywriting: {}
            , loginType: 'enterprise'
            , loginTypeOptions: [{
                title: this.$t('form.individual'),
                value: 'individual'
            }, {
                title: this.$t('form.enterprise'),
                value: 'enterprise'
            }],
            auth_code: '',
            tmpToken: '',
            path: window.location.pathname,
            locale: localStorage.getItem('locale'),
        }
    }, watch: {
        $route() {
            this.$nextTick(this.toggleMenu('close'))
            this.$nextTick(this.getRealVH())
            this.path = window.location.pathname
        },
    }, computed: {
        isDashboard() {
            return this.path.startsWith('/dashboard');
        },
        isDashboardPro() {
            console.log(this.path)
            return this.path.startsWith('/dashboard-pro');
        }
    }, methods: {
        changeLocale() {
            if (this.locale === 'zh_HK') {
                this.locale = 'en_US'
            } else {
                this.locale = 'zh_HK'
            }
            this.$i18n.locale = this.locale
            localStorage.setItem('locale', this.locale)
            if (this.path.indexOf('/product/') == 0) {
                // strategy render id differed in lang
                window.location.href = '/'
            } else {
                window.location.reload()
            }
        },
        regisFormValidation(){
            if(this.regisData.username.indexOf("@") == -1){
                this.$toast.error(this.$t('text.pleaseCheckYourEmail'), {
                    timeout: 2000
                });
                return false;
            }else if(this.regisData.password.length < 8){
                this.$toast.error(this.$t('text.atLeastEightDigit'), {
                    timeout: 2000
                });
                return false;
            }else if(this.regisData.password != this.regisData.repeatPassword){
                this.$toast.error(this.$t('text.pleaseConfirmYourPwd'), {
                    timeout: 2000
                });
                return false;
            }else{
                return true;
            }
        },
        handleRegis(){
            if(!Cookies.get('dcbotLogin') && !Cookies.get('dcbotProLogin')){
                if(this.regisFormValidation()){
                    $('.regis-confirm').addClass('loading');
                    apiRegis({
                        email: this.regisData.username,
                        password: md5(this.regisData.password + process.env.VUE_APP_MD5_SALT)
                    }).then(res => {
                        $('.regis-confirm').removeClass('loading');
                        if(res.data.status){
                            $('.login-modal .content').addClass('regis-complete');
                            var last = 5;
                            const closeModal = setInterval(() => {
                                last -= 1;
                                $('.regis-success').find('p').eq(1).html(`視窗將於 ${last} 秒後關閉...`);
                                if(last <= 0){
                                    $('.login-modal').fadeOut(300);
                                    $('.login-modal .content').removeClass('regis-complete');
                                    clearInterval(closeModal);
                                }
                            }, 1000)
                        }else{
                            this.$toast.error(res.data.msg, {
                                timeout: 2000
                            });
                        }
                    })
                }
            }else{  
                this.$toast.error(this.$t('text.alreadyLogin'), {
                    timeout: 2000
                });
            }
        },
        handleLogin(){
            if(this.loginType == 'individual'){
                if(!Cookies.get('dcbotLogin') && !Cookies.get('dcbotProLogin')){
                    $('.login-confirm').addClass('loading');
                    apiLogin({
                        email: this.loginData.username,
                        password: md5(this.loginData.password + process.env.VUE_APP_MD5_SALT),
                        //password: this.loginData.password
                    }).then(res => {
                        $('.login-confirm').removeClass('loading');
                        if(res.data.status){
                            var expireDate = new Date(new Date().getTime() + 600 * 100000);
                            Cookies.set('dcbotLogin', res.data.u_id, { expires: expireDate });
                            Cookies.set('dcbotLogin_uname', res.data.nickname && res.data.nickname != "" ? res.data.nickname : res.data.username, { expires: expireDate });
                            window.location = "/dashboard";
                        }else{
                            this.$toast.error(res.data.msg, {
                                timeout: 2000
                            });
                        }
                    });
                }else{  
                    this.$toast.error("您已登入！若欲切換用戶類型，請先登出。", {
                        timeout: 2000
                    });
                }
            }else{
                if(!Cookies.get('dcbotLogin') && !Cookies.get('dcbotProLogin')){
                    $('.login-confirm').addClass('loading');
                    apiLoginPro(this.loginData.username, MD5(this.loginData.password)).then(res => {
                        $('.login-confirm').removeClass('loading');
                        if(res.data.result){
                            var uInfo = verifyToken(res.data.token);
                            if(uInfo.role.length == 0){
                                this.tmpToken = res.data.token;
                                $('.form-container').removeClass('active');
                                $('.form-container.tfa').addClass('active');
                                $('.toggle-container').hide();
                            }else{
                                var expireDate = new Date(new Date().getTime() + 600 * 100000);
                                Cookies.set('dcbotProLogin', res.data.token, { expires: expireDate });
                                Cookies.set('dcbotLogin_uname', uInfo.name, { expires: expireDate });
                                Cookies.set('dcbotLogin_role', uInfo.role, { expires: expireDate });
                                window.location = "/dashboard-pro";
                            }
                        }else{
                            this.$toast.error(res.data.err_msg, {
                                timeout: 2000
                            });
                        }
                    });
                }else{  
                    this.$toast.error("您已登入！若欲切換用戶類型，請先登出。", {
                        timeout: 2000
                    });
                }
            }
        },
        handleTfa(){
            apiTfa(this.auth_code, this.tmpToken).then(res => {
                if(res.data.result){
                    var uInfo = verifyToken(res.data.token);
                    var expireDate = new Date(new Date().getTime() + 600 * 100000);
                    Cookies.set('dcbotProLogin', res.data.token, { expires: expireDate });
                    Cookies.set('dcbotLogin_uname', uInfo.name, { expires: expireDate });
                    Cookies.set('dcbotLogin_role', uInfo.role, { expires: expireDate });
                    window.location = "/dashboard-pro";
                }else{
                    this.$toast.error(res.data.err_msg, {
                        timeout: 2000
                    });
                }
            })
        },
        oAuthSignIn(provider, id_token){
            if(!Cookies.get('dcbotLogin') && !Cookies.get('dcbotProLogin')){
                switch(provider){
                    case 'google':
                        apiGoogleLogin({
                            id_token: id_token
                        }).then(res => {
                            if(res.data.status){
                                var expireDate = new Date(new Date().getTime() + 600 * 100000);
                                Cookies.set('dcbotLogin', res.data.u_id, { expires: expireDate });
                                Cookies.set('dcbotLogin_uname', res.data.nickname && res.data.nickname != "" ? res.data.nickname : res.data.username, { expires: expireDate });
                                window.location = "/dashboard";
                            }else{
                                this.$toast.error(res.data.msg, {
                                    timeout: 2000
                                });
                                var auth2 = window.gapi.auth2.getAuthInstance();
                                auth2.signOut().then(function () {
                                    console.log('User signed out.');
                                });
                            }
                        })
                        break;
                    case 'facebook':
                        console.log(id_token)
                        break;
                    default:
                        console.log('err')
                }
            }
        },
        handleLogout(){
            if(Cookies.get('dcbotLogin')){
                apiLogout().then(res => {
                    if(res.data.status){
                        Cookies.remove('dcbotLogin');
                        Cookies.remove('dcbotLogin_uname');
                        window.location = '/';
                    }else{
                        this.$toast.error(res.data.msg, {
                            timeout: 2000
                        });
                    }
                    try {
                        var auth2 = window.gapi.auth2.getAuthInstance();
                        auth2.signOut().then(function () {
                            console.log('User signed out.');
                        });
                    } catch (error) {
                        console.log(error)
                    }
                });
            }else if(Cookies.get('dcbotProLogin')){
                Cookies.remove('dcbotProLogin');
                Cookies.remove('dcbotLogin_uname');
                Cookies.remove('dcbotLogin_role');
                window.location = '/';
            }else{  
                this.$toast.error(this.$t('text.pleaseLogin'), {
                    timeout: 2000
                });
                Cookies.remove('dcbotLogin');
                Cookies.remove('dcbotLogin_uname');
                window.location = '/';
            }
        },
        loadProductsList(){
            apiProducts().then(res => {
                if(res.data.status){
                    this.products = res.data.product_list
                }
            })
        },
        toggleMenu(to = ""){
            if(to == "")this.expandNav = !this.expandNav
            if(to == "close"){
                this.mobileExpandNav = false
                $('.dashboard-overlay').fadeOut(300)
            }else{
                this.mobileExpandNav = !this.mobileExpandNav
                if(this.mobileExpandNav){
                    $('.dashboard-overlay').fadeIn(300)
                }else{
                    $('.dashboard-overlay').fadeOut(300)
                }
            }
        },
        toggleOutsideMenu(to = ""){
            if(to == "close"){
                this.mobileExpandOutsideNav = false
                $('.mobile-menu-overlay').fadeOut(300)
            }else{
                this.mobileExpandOutsideNav = true
                $('.mobile-menu-overlay').fadeIn(300)
            }
        },
        getRealVH(){
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            window.addEventListener('resize', () => {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            });
        },
        loadCopywriting(){
            apiGetHomeConfig().then(res => {
                if(res.data.status){
                    this.copywriting = res.data.data
                }
            })
        },
        getStarted(){
            window.open('https://t.me/DerivativesCN', '_blank').focus();
        }
    }, mounted() {
        this.getRealVH();
        this.loadProductsList();
        this.loadCopywriting();
        var self = this;
        if(!this.needLoading.includes(this.$route.name))$('.global-loading').delay(1500).fadeOut(300);
        $('body').on('click', '.toggle-container > h2:not(.active)', function(){
            $('.form-container').removeClass('active');
            $(`.form-container.${$(this).data('target')}`).addClass('active');
            if($(this).data('target') == 'regis'){
                self.loginType = 'individual';
            }
            $('.toggle-container > h2').removeClass('active');
            $(this).addClass('active');
        })
        $('body').on('click', '.global-nav .login-btn', function(){
            $('.toggle-container').show();
            $('.login-modal').fadeIn(300).css('display', 'flex');
            $('.form-container').removeClass('active');
            $('.form-container.login').addClass('active');
            $('.toggle-container > h2').removeClass('active');
            $('.toggle-container > h2[data-target=login]').addClass('active');
        })
        $('body').on('click', '.global-nav .regis-btn', function(){
            $('.toggle-container').show();
            $('.login-modal').fadeIn(300).css('display', 'flex');
            $('.form-container').removeClass('active');
            $('.form-container.regis').addClass('active');
            $('.toggle-container > h2').removeClass('active');
            $('.toggle-container > h2[data-target=regis]').addClass('active');
        })
        $('body').on('click', '.login-modal', function(){
            $('.login-modal').fadeOut(300);
        })
        $('body').on('click', '.login-modal > .content', function(e){
            e.stopPropagation();
        })
    },
}
</script>