import req from './https.js'

export const apiLogin = (params) => req('post', '/users/login', params)
export const apiLogout = () => req('get', '/api/logout')
export const apiRegis = (params) => req('post', '/users/register', params)
export const apiEmailVerify = (params) => req('get', `/users/email_verify/${params.uid}/${params.vid}`)
export const apiGoogleLogin = (params) => req('post', '/users/google', params)
export const apiGetInfo = (params) => req('get', '/api/user', params)
export const apiSetInfo = (params) => req('post', '/api/save_user_info', params)
export const apiCheckKey = (params) => req('get', '/api/check_api_key', params)
export const apiSetKey = (params) => req('post', '/api/set_api_key', params)
export const apiResetPasswordRequest = (params) => req('post', '/users/reset_pass_request', params)
export const apiResetPasswordVerify = (params) => req('get', `/users/reset_pass_verify/${params.uid}/${params.vid}`)
export const apiResetPassword = (params) => req('post', `/users/reset_password`, params)