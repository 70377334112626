// 後台登入
import req from './https.js'

export const apiLoginPro = (username, password) => req('post', `/login?username=${username}&password=${password}`)
export const apiTfa = (auth_code, token) => req('post', `/mfa?auth_code=${auth_code}`, null, token)
export const apiUserList = () => req('get', '/user_list')
export const apiUserRegis = (params) => req('post', `/user_register?${new URLSearchParams(params).toString()}`)
export const apiGetUserInfo = () => req('get', `/user_info`)
export const apiDeleteUser = (params) => req('delete', `/user_info?${new URLSearchParams(params).toString()}`)
export const apiUserInfo = (params) => req('post', `/user_info?${new URLSearchParams(params).toString()}`)
export const apiUserRole = (params) => req('post', `/user_role?${new URLSearchParams(params).toString()}`)
export const apiRenewPassword = (params) => req('post', `/renewPwd?${new URLSearchParams(params).toString()}`)
export const apiGetUserShare = (params) => req('get', '/user_shares', params)
export const apiSetUserShare = (params) => req('post', `/user_shares?${new URLSearchParams(params).toString()}`)
export const apiDeleteUserShare = (params) => req('delete', `/user_shares?${new URLSearchParams(params).toString()}`)